import VueRouter from 'vue-router'
import Vue from 'vue'
Vue.use(VueRouter)

const routes = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'Index',
            component: () => import('../views/index.vue')
        },
        {
            path: '/oneDriveLink',
            name: 'OneDriveLink',
            component: () => import('../views/oneDriveLink.vue')
        },
        {
            path: "/clipboard",
            name: "Clipboard",
            component: () => import('../views/clipboard.vue')
        }
    ]
})
export default routes
